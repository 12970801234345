#dispute-resolution{
  .modal-header {
    display: inline-block;
    background: #F1F7FA;
    padding: 2.2em 1.3em;
  }
  .modal-body {
    padding: 0px !important;
  }
  .dispute-header-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    img {
      width: 1em;
      height: 1em;
    }
  }
  .complaint-session-container {
    width: 100%;
    min-height: 32em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .complaint-session-top {
    background: #F2F2F2;
    height: 4em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.8em;
    .report-button {
      background: #FF6634;
      box-shadow: 0px 4px 4px rgba(255, 102, 52, 0.25);
      border-radius: 4px;
      color: #FFFFFF;
      padding: 0.28em 0.7em;
      cursor: pointer;
    }
  }
  .complaint-card-group {
    display: flex;
    justify-content: space-between;
    padding: 1.4em;
    .complaint-card-right {
      display: flex;
      align-items: center;
      img {
        margin-right: 1em;
      }
      .ticket-number {
        font-size: 0.9em;
      }
      .ticket-time {
        font-size: 0.75em;
        color: #828282;
      }
    }
    .complaint-card-left {
      .text {
        font-size: 0.75em;
      }
      .resolved {
        color: #27AE60;
      }
      .unresolved {
        color: #D4AF37;
      }
    }
  }
  .transaction-period-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    input {
      border: 1px solid rgba(228, 228, 228, 0.6);
      box-sizing: border-box;
      border-radius: 5px;
      width: 9em;
      height: 3em;
      padding: 0px 2em;
    }
  }
  .tansaction-input-container {
    width: 100%;
    textarea {
      border: 1px solid rgba(228, 228, 228, 0.6);
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      height: 3em;
      padding: 0.3em 0.6em;
    }
  }
}