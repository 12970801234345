.Group-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 19px;
  color: #000000;
}
.bio {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #1c1c1c;
}

.btn1 {
  width: 7rem;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}
.rec {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 10px;
}
.create-post {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 32px;
  width: 55%;
  padding: 10px 18px;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.request-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  .request {
    display: flex;
    width: 100%;
    border-top: 1px solid #dee2ea;
    border-bottom: 1px solid #dee2ea;
    padding: 1.5rem;
    .req-img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 12px;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
    .req-det {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 42px);
      .det-det {
        display: flex;
        flex-direction: column;
      }
      .det-button {
        display: flex;
        .btn-acc {
          background: #27ae60;
          border: #27ae60;
          border-radius: 2px;
          padding: 5px 6px;
          text-align: center;
          color: #fbfbfb;
          margin-right: 21px;
          width: 80px;
        }
        .btn-del {
          background: #ff3a44;
          border: #ff3a44;
          border-radius: 2px;
          padding: 5px 6px;
          text-align: center;
          color: #fbfbfb;
          width: 80px;
        }
        .btn-following {
          background: #ff6634;
          border: #ff6634;
          border-radius: 2px;
          padding: 5px 6px;
          text-align: center;
          color: #fbfbfb;
          width: 80px;
          margin-right: 21px;
        }
        .btn-follow {
          background: #fbfbfb;
          border: 0.5px solid #ff6634;
          border-radius: 2px;
          padding: 5px 6px;
          text-align: center;
          color: #ff6634;
          margin-right: 21px;
          width: 80px;
        }
      }
    }
  }
}

.settings-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 80px;

  .part-1 {
    margin-bottom: 20px;
    .head {
      display: flex;
      flex-direction: column;
      .top {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 11px;
        color: #1c1c1c;
      }
      .bottom {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 11px;
        color: #4f4f4f;
      }
    }
    .h-bottom {
      background: #ffffff;
      box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      width: 100%;
      padding: 22px 16px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #1c1c1c;
      select {
        width: 100%;
        border: 0 !important;
      }
    }
  }
  .part-2 {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    width: 100%;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
    padding: 22px 16px;

    .left {
      display: flex;
      flex-direction: column;
      .head {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #1c1c1c;
      }
      .bottom {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #4f4f4f;
      }
    }
    .right {
    }
  }
}
.group-search {
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  input {
    width: 70%;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
    ::-webkit-input-placeholder {
      text-align: center;
    }

    :-moz-placeholder {
      text-align: center;
    }
  }
}
.private-acc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .pactop {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;

    color: #1c1c1c;
  }
  .pacbottom {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    color: #828282;
  }
}
