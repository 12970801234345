.table-div {
  width: 100%;
  table {
    thead {
      background: rgba(231, 71, 44, 0.16);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #e7472c;
    }
  }
}

.active1 {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #060606;
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.role {
  width: 100%;
  display: flex;
  background: #ffffff;
  border: 0.5px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px #e5e5e5;
  border-radius: 4px;
  .role-p-1 {
    width: 20%;
    display: flex;
    flex-direction: column;
    border-right: 0.5px solid #e0e0e0;
    .role-p-1-1-head {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      padding: 23.75px 19px;
      text-align: center;
    }
    .role-p-1-1-body {
      display: flex;
      flex-direction: column;
      .active {
        background: #ffefe9;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #ff6634;
      }
      .role-p11-body-item {
        padding: 23.75px 19px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding-top: 12px;
        padding-bottom: 12px;
        color: #828282;
        border-top: 0.5px solid #f2f2f2;
        border-bottom: 0.5px solid #f2f2f2;
      }
    }
    .role-p-1-2-head {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      padding: 23.75px 19px;
      text-align: center;
    }
    .role-p-1-2-body {
      display: flex;
      flex-direction: column;
      padding: 23.75px 19px;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #828282;
    }
  }
  .role-p-2 {
    width: 80%;
    padding: 23.75px 19px;
    display: flex;
    flex-direction: column;
    .role-p-2-head {
      display: flex;
      flex-direction: column;
      .role-p-2-h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
      .role-p-2-h2 {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        color: #060606;
      }
    }
    .role-p-2-head-2 {
      width: 100%;
      margin-top: 17px;
      margin-bottom: 20px;
      background: #ffffff;
      border: 1px solid #f1f1f1;
      box-sizing: border-box;
      border-radius: 2px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #828282;
      padding: 7px;
    }
    .body-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .permitted {
        width: 48.3%;

        l li:nth-of-type(odd) {
          background-color: #e9e9f9;
          // }
        }
      }
      .not-permitted {
        width: 48.3%;
      }
      li:nth-of-type(odd) {
        background-color: #e9e9f9;
        // }
      }
    }
  }
}

// .role-head {
//   background: #ffffff;
//   border: 1px solid #ebebeb;
//   box-sizing: border-box;
//   border-radius: 5px;
//   width: 100%;
//   padding-left: 10px;
//   padding-right: 10px;
//   .role-options {
//     height: 50px;
//     display: flex;
//     align-items: center;
//     color: #b6b3b3;
//     .options {
//       margin-right: 25px;
//     }
//   }
// }

// .role-body {
//   .rb-head {
//     margin-top: 30px;
//     margin-bottom: 20px;
//     .main {
//       font-family: Inter;
//       font-style: normal;
//       font-weight: 600;
//       font-size: 18px;
//       line-height: 22px;
//       color: #060606;
//       margin-bottom: 10px;
//     }
//     .temp {
//       font-family: Inter;
//       font-style: normal;
//       font-weight: normal;
//       font-size: 14px;
//       line-height: 17px;
//       /* identical to box height */

//       color: #060606;
//     }
//   }
//   .body-content {
//     display: flex;
//     justify-content: space-between;
//     .permitted {
//       width: 48.3%;

//       l li:nth-of-type(odd) {
//         background-color: #e9e9f9;
//         // }
//       }
//     }
//     .not-permitted {
//       width: 48.3%;
//     }
//     li:nth-of-type(odd) {
//       background-color: #e9e9f9;
//       // }
//     }
//   }
// }
