.page-count-countainer {
  display: flex;
  justify-content: space-between;
  width: 80%;
  .navigation-arrow {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
  span {
    font-family: Lato;
    font-weight: normal;
    font-size: 13px;
    color: #333333;
  }
}