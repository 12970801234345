//@import url('https://fonts.googleapis.com/css2?family=Lato');

// $font-stack: 'Work Sans', sans-serif;
// $font-d1: 'Libre Baskerville';
// 'Lato', sans-serif: 'Lato', sans-serif;
// $primary: #FF6700;
// #FF6700: #FF6700;
// $secondary-color: #828282;
// $secondary-light: #c4c4c4;
// $secondary-dark: #2d2b2a;
// $primary-link: #0890a3;
// $color-dblue: #064a72;
// $text-color: #4F4F4F;

body {
  font-family: 'Lato', sans-serif;
}

.custom-center {
  text-align: center;
  color: #0e71eb;
}

.text-cmuted {
  color: #b6b3b3;
}

p,
h4,
h5,
span,
.lato-text {
  font-family: 'Lato', sans-serif;
}

// @import '../../../node_modules/bootstrap/scss/bootstrap';
// @import '../../_BootstrapImport.scss';
