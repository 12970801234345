#wayagram-profile-modal,
#wayagram-create-group {
  .modal-body {
    padding: 0;
  }
  .profile-header-container {
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: space-between;
    img {
      width: 0.85em;
      cursor: pointer;
    }
  }
  .banner-div {
    position: relative;
    img {
      background-size: cover;
      height: 180px;
      width: 100%;
    }
    .upload-banner-input,
    .upload-banner-input2 {
      height: 30px;
      width: 30px;
      left: 225px;
      top: 50px;
      position: absolute;
    }
    .upload-banner-input2 {
      width: 103px;
      opacity: 0;
    }
  }
  .avatar-section {
    position: relative;
    img {
      height: 110px;
      width: 110px;
      position: absolute;
      left: 16px;
      top: -40px;
      border-radius: 50%;
    }
    .upload-input {
      height: 30px;
      width: 30px;
      left: 52px;
      top: 0;
      position: absolute;
    }
    .upload-input2 {
      opacity: 0;
    }
  }
  .form-div {
    margin-top: 75px;
  }
}

.profile-avatar-div {
  position: relative;
  input {
    position: absolute;
    left: 140px;
    opacity: 0;
    height: 100px;
    width: 100px;
  }
  img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
}
