.contents {
  width: 100%;
  padding: 10px 25px;
  margin: 10px 0px;
  border: 1px solid #e2e0e0;
  box-sizing: border-box;
}
.search {
  width: 70%;
}
.left {
  width: 50%;
}
