#receipt-wrapper {
  .body-wrapper {
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    background-color: #fff;
  }
  .top {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  .bottom {
    border-top: 1px solid #e0e0e0;
  }

  .modal-title {
    display: flex;
    width: 100%;
  }
}
