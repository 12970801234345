#manage-bank-modal,
#manage-bank-list-modal,
#available-bank-modal {
  .modal-content {
    padding: 20px;
  }
  .header {
    border: none !important;
    .h-left img {
      height: 29px;
    }
    .h-right img {
      height: 29px;
    }
  }
  .img-center {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    img {
      height: 200px;
      margin-bottom: 20px;
    }
    h4 {
      width: 56%;
      margin: 0 auto;
      font-style: normal;
      font-weight: 200;
      font-size: 25px;
      @media (max-width: 426px) {
        width: 100% !important;
      }
    }
  }
  .modal-footer {
    border: none;
  }

  .bank-list-div,
  .wallet-list-div,
  .card-list-div {
    margin-top: 40px;
    box-shadow: 0 2px 4px #888;
    filter: drop-shadow(0px 2px 4px rgba(40, 41, 61, 0.04))
      drop-shadow(0px 8px 16px rgba(96, 97, 112, 0.16));
    padding: 10px;
    .img-div img {
      height: 60px;
    }
  }

  .wallet-list-div,
  .card-list-div {
    .img-div img {
      height: 30px !important;
      width: 60px;
    }
  }

  .account-details {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

#manage-bank-list-modal .modal-content {
  padding-bottom: 100px;
}

#available-bank-modal .header img {
  height: 16px !important;
}
