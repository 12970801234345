#post-modal {
    .modal-content {
        padding: 20px;
        border-radius: 12px;
    }
}

#create-post-modal {
    .modal-header {
        background: #f1f7fa;
        button {
            border: none;
        }
    }
    .modal-footer {
        display: inline-flex;
        justify-content: space-between;
    }
    .image-div {
        width: 3.125em;
        height: 3.125em;
        margin-right: 5px;
        img {
            width: 1.88em;
            height: 1.88em;
            background-size: cover;
            border-radius: 50%;
        }
    }
    textarea {
        border: none;
        min-height: 16vh;
    }
}

#create-moment-modal {
    .modal-header {
        background: #f1f7fa;
    }
    .modal-body {
        background: #064672;
        padding-bottom: 0.5rem;
    }
    .modal-footer {
        background: #064672;
        border: none;
        .add-cursor {
            .galler-icon {
                filter: brightness(0) invert(1);
            }
        }
        .add-cursor:last-child {
            border-right: 1px solid #F1F7FA;
            padding-right: 20px;
        }
    }
    .modal-footer:last-child {
        border-top: 1px solid darkgray;
    }
    .display-button {
        width: 30%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 20px;
        color: #fff;
        button {
            color: #fff;
            border: none;
            background: none;
            outline: none;
        }
    }
    .image-div {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        img {
            width: 35px;
            height: 35px;
            background-size: cover;
            border-radius: 50%;
            margin-right: 12px;
        }
        div {
            color: #fff;
        }
    }
    textarea {
        text-align: center;
        vertical-align: middle;
        padding: 40px 0;
        font-size: 24px;
        color: #fff;
        height: 130px;
        border: none;
        background: #064672;
        box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.1);
        border-radius: 14px;
    }
}

#wayagram-create-group {
    .modal-title {
        display: flex;
        width: 100%;
    }
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .header-close {
        background: none;
        border: 0;
    }
}

.inner-wrapper {
    .wrapper {
        display: flex;
        .left-gram {
            width: 20%;
            display: flex;
            padding: 10px 20px;
            justify-content: center;
            .profile-image {
                width: 38px;
                height: 38px;
                border-radius: 50%;
            }
        }
        .right-gram {
            padding: 10px 40px 10px 0;
            .inner-right {
                display: flex;
                font-weight: 12px;
                margin-top: 12px;
                h6 {
                    margin-right: 20px;
                }
                .handle {
                    font-weight: bolder;
                    color: grey;
                }
            }
            .gram-content {
                margin-top: 20px;
                margin-bottom: 20px;
                text-align: justify;
            }
            .post-image {
                width: 100%;
                border-radius: 8px;
            }
            .gram-icon {
                display: flex;
                padding: 20px 0;
                .icon-container {
                    width: 25%;
                    .icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
    .second-wrapper {
        align-items: center;
        .right-gram {
            width: 80%;
            input {
                border: 1px solid lightgray;
                border-radius: 4px;
                width: 100%;
                padding: 10px;
            }
        }
    }
    .button-wrapper {
        display: flex;
        justify-content: flex-end;
        padding: 10px 40px;
        margin-bottom: 20px;
        .send-button {
            background: #ffc634;
            border-radius: 4px;
            padding: 6px 12px;
            border: none;
            color: #fff;
        }
    }
}

.header-box {
    padding-left: 15px;
}

#poll-disclaimer {
    width: 17em;
    .modal-content {
        border-radius: 0.9em;
    }
    .modal-body {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px;
        .disclaimer-content {
            text-align: center;
            padding: 1.5em 1.5em 0 1.5em;
        }
        .button-group {
            display: flex;
            justify-content: space-between;
            height: 2.75em;
            margin-top: 0.9em;
            border-top: 0.5px solid rgba(60, 60, 67, 0.29);
            .box {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 50%;
                color: #007aff;
                cursor: pointer;
            }
            .border-right {
                border-right: 0.5px solid rgba(60, 60, 67, 0.29);
            }
        }
    }
}