.progress-bar-group {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  margin-bottom: 1em;
  .bar {
    width: 3.2em;
    height: 4px;
    border-radius: 2px;
    margin-left: 1em;
    background-color: #f2f2f2;
  }
  .active {
    background-color: #27AE60;
  }
}