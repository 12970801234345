.interest-container {
    min-height: 500px;
    // width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.interest-header {
    background: #f1f7fa;
    text-align: center;
    padding: 2rem 0;
    border-radius: 1.2em 1.2em 0 0;
}
.no-inte{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    color: #333333;

}
.interest-p1 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    color: #333333;
}

.description-text {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #828282;
    margin: 0px;
}

.mobile-header {
    background: #fff;
    display: none;
}

@media screen and (max-width: 957px) {
    .normal-header {
        display: none;
    }
    .mobile-header {
        display: block;
    }
    .recommend-image {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .recommend-group {
        width: 80%;
        box-shadow: 0px;
        padding: 0px;
        border: none;
    }
}

.interest-card {
    background: #fff;
    border-radius: 14px;
    width: 120px;
    min-height: 120px;
    margin: 0 1rem 1rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    position: relative;
    .interest-card-img {
        width: 24px;
        height: 24px;
        margin-bottom: 12px;
    }
    .interest-card-p1 {
        margin: 0px;
        text-align: center;
    }
    .interest-status-icon {
        position: absolute;
        right: 5px;
        top: 5px;
    }
}

.surprise-card {
    width: 40%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 6px;
    .surprise-card-img {
        margin-right: 14px;
    }
}

.recommend-container {
    padding: 60px 22px;
}

.recommend-card {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recommend-group {
    width: 60%;
    padding: 15px 0px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
    border-radius: 6px;
    p {
        margin: 0px;
    }
    .recomment-card-p2 {
        color: #828282;;
    }
}

.follow-button {
    width: 120px;
    height: 40px;
    border: 1px solid #ff6634;
    box-sizing: border-box;
    border-radius: 2px;
    color: #ff6634;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 8px 20px;
}

.follow-button:hover {
    background: #ff6634;
    color: #fff;
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
    cursor: pointer;
}

.recommend-image {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 2.5rem;
    margin-right: 0.3rem;
    border-radius: 50%;
}

.recomment-car-p1 {
    color: yellow;
    font-size: 1rem;
}

.finish-card {
    width: 60%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 6px;
    background: #ff6634;
    // margin-top: 2rem;
    color: #fff;
    cursor: pointer;
    margin-bottom: 4em;
}

.finish-card:hover {
    background: #fff;
    border: 1px solid #ff6634;
    color: #ff6634;
}

.interest-card:hover,
.surprise-card:hover,
.recommend-group:hover {
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
    cursor: pointer;
}

.card-container {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 4% 0 4%;
    justify-content: space-evenly;
}

.selected-interest {
    background-color: gold;
}

#interest-modal {
    .custom-modal-style {
        width: 980px;
        border-radius: 1.2em;
    }
    
    .modal-body {
        padding: 0px !important;
        min-height: 700px;
        border-radius: 1.2em;
    }
    
    .modal-content {
        border-radius: 1.2em !important;
        padding: 0px !important;
    }
}


.wayagram-handle {
    padding: 2em 1em 0 1em;
    input {
        border: 0px;
    }
    hr {
        margin: 2px;
    }
}

.description-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.description-text {
    width: 70%;
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
}