// @import './assets/stylesheets/index.scss';
// @import './assets/stylesheets/animation.scss';

* {
  font-family: 'Lato', sans-serif;
}
button{
  text-transform: uppercase;
}
.hover:hover {
  background-color: #ff6700;
  color: #fff0eb;
}

.list-item {
  display: 'inline-block';
  flex-basis: 50%;
  margin-left: '0.5em';
}

.ant-table-thead > tr > th {
  background-color: #fff0eb;
  color: #ff6700;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #ff6700 !important;
  font-weight: 700;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff4b01;
}
.product-content {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
  }
}
.web-product-tab {
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    background-color: #f8f8f8;
  }
}
.ant-tabs-tab:hover {
  color: #ff4b01 !important;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #ff4b01;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  // color: #FF4B01 !important;
  font-weight: 500;
}
.ant-tabs-tab:hover {
  color: #ff4b01 !important;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}

.ui.primary.button {
  background: #0e71eb;
  color: #ffffff;
  border-radius: 24px;
  font-family: san-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 31px;
  line-height: 5px;
}

body,
html {
  height: 100%;
  overflow: hidden;
}

// #app {
//   height: 100vh;
// }

*:focus {
  outline: none;
}

textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  overflow: auto;
}

// #app {
//   height: 100%;
//   min-height: 100vh;
// }

.h-100 {
  height: 100%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.add-cursor {
  cursor: pointer;
}

.text-primary {
  color: #ff6700;
}

.primary-link {
  color:#ff6634;
}

.custom-blue {
  color:#6700ff;
}

.active {
  color: #ff6700;
}

.active-bottom {
  border-bottom: 1px solid #ff6700;
}

.btn-primary {
  color: #fff !important;
  background: #ff6700;
  border: none !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
}

.bg-primary {
  background-color: #ff6700;
}

.text-secondary {
  color: #ff6700;
}

.text-secondary-light {
  color: #ff6700;
}

.text-secondary-dark {
  color: #ff6700;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

// toggle input password
.toggle-pass-wrapper {
  position: relative;
}

.pass-icon {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  right: 0.5rem;
  color: #ff6700;
  z-index: 2;
}

// otp inputs
.otp-inputs {
  width: 3rem !important;
  height: 3rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.otp-input {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  @media (max-width: 376px) {
    width: 2rem !important;
  }
}

.otp-input2 {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  @media (max-width: 376px) {
    width: 2rem !important;
    margin: 0 !important;
  }
}

.otp-bottom {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.no-deco {
  text-decoration: none !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.s-text {
  font-size: 10px;
  color: #b6b3b3;
}

.font-d1 {
  font-family: sans-serif;
}

.hide-now {
  opacity: 0;
}

// Modal backdrop
.modal {
  background: rgba(20, 39, 78, 0.19607843137254902) !important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

// splash screen
.splash-bg {
  background: linear-gradient(
    166.75deg,
    #f88e36 22.53%,
    #ff6634 74.06%
  ) !important;
}

// toggle hide

// MAP SECTION
.map-wrap {
  background-color: rgba(128, 128, 128, 0.548);
  width: 90%;
  height: 246px;
  border-radius: 5px 5px 0 0;
}

.mapActions {
  width: 90%;
  display: flex;
}

.mapActions div {
  width: 100%;
  border: 1px solid grey;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: green;
  border-color: green;
}

// ::-webkit-scrollbar {
//   display: none !important;
//   opacity: 0 !important;
// }
.g-ussd-code {
  border: #f88e36;
}

.custom-scroll-bar {
  --scrollbarBG: #fff;
  --thumbBG: #d4cfcf;
}
.custom-scroll-bar::-webkit-scrollbar {
  width: 8px;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.custom-scroll-bar::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.custom-scroll-bar::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 10px;
  border: 1px solid var(--scrollbarBG);
}

.btn-orange-lite {
  color: orangered;
  border-color: orangered;
  background-color: rgb(245, 212, 200);
}
.btn-orange-lite:hover {
  color: rgb(250, 105, 52);
  background-color: rgb(243, 223, 215);
}
.pt-4-5 {
  padding-top: 30px;
}
.text-orange {
  color: #ff6700;
}
.fs-05 {
  font-size: 13px;
}
.fs-01 {
  font-size: 10px;
}
.fw-bold-sm {
  font-weight: 540;
}
.lh-01 {
  line-height: 0px;
}
.border-orange {
  width: 50px;
  border-bottom: 2px solid orangered;
  // border-height: 50px;
}
.rounded-6 {
  border-radius: 20px;
}
.bg-orange-lite {
  background-color: #faebe7;
  color: #ff6700;
}
