// @import '../../../assets/stylesheets/index.scss';

.status {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #828282;
  margin: 0;
}
.status-active {
  color: #219653;
}
.status-not-in-use {
  color: #bdbdbd;
}
.color-black {
  color: #000000;
}

.explore-tag {
  background-color: rgba(6, 74, 114, 0.2) !important;
  border: 1px solid #064a72 !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  color: #064a72 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: 0.5px;
  cursor: pointer;
  width: 131px;
  text-align: center;
}
.status-tag {
  box-sizing: border-box !important;
  border: 1px solid #828282;
  border-radius: 2px !important;
  color: #828282 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: 0.5px;
  width: 131px;
  text-align: center;
}
.active-status-tag {
  border: 1px solid #219653 !important;
  color: #219653 !important;
}
.card-h {
  height: 110px;
}
.dashboard-two-col-wrap {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // overflow-y: auto;
  // height: calc(100vh - 70px);
  // @media (max-width: 426px) {
  //   height: 80vh;
  // }
}
.product-content {
  padding: 25px;
  .ant-table-thead > tr > th {
    background-color: #fff0eb !important;
    color: #ff6700 !important;
  }
  .ant-table-container table > thead > tr:first-child th:nth-child(2) {
    text-align: center;
  }
  .ant-table-container table > thead > tr:first-child th:nth-child(3) {
    text-align: right;
    padding-right: 30px;
  }
  .ant-table-container table > tbody > tr:nth-child(n + 2) td:nth-child(2) {
    text-align: center;
  }
  .ant-table-container table > tbody > tr:nth-child(n + 2) td:nth-child(3) {
    text-align: right;
  }
}

.kyc-upload-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.kyc-upload-btn {
  width: 107px;
  height: 43px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.ant-input,
.ant-select,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
}
.ant-input {
  padding-left: 6px !important;
}
.ant-btn-primary {
  width: 149px;
  height: 29px;
  background: #ff6700 !important;
  border-color: #ff6700 !important;
  border-radius: 10px !important;
  margin-left: 5px;
  margin-top: 5px;
}
.waya-btn {
  width: 129.14px;
  height: 58px;
  text-align: center;
  border-radius: 5px;
  border: 0px;
  margin: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}
.bkg-green {
  background: #05b862;
}

.bkg-orange {
  background: #ff6700;
}
::placeholder {
  padding-left: 7px;
}
.ant-form-item-label > label {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.product-kyc-steps {
  width: 751px;
  height: 87px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  align-self: center;
  margin: auto;
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  .step {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    hr {
      opacity: 0.5;
      color: #ddd7d7;
      width: 150px;
      margin-left: 5px;
    }
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #ddd7d7 !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: #000000;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description
    :hover {
    font-weight: bold;
    color: #ff6700;
  }
}

.product-kyc-content {
  .kyc-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .progress-text {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #000000;
    text-align: center;
    margin-top: 5px;
  }
  .ant-progress-circle .ant-progress-text {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0.3px;
  }
  .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #ff6700;
  }
  margin-top: 24px;
  .product-kyc-form {
    background: #ffffff;
    border: 1px solid #ddd7d7;
    box-sizing: border-box;
    border-radius: 10px;
    .product-kyc-form-box {
      background: rgba(242, 242, 242, 0.77);
      border-radius: 10px;
      padding: 15px;
      margin: 5px;
      p {
        font-family: DM Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.3px;
        color: #000000;
      }
      h5 {
        font-family: DM Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.3px;
        color: #000000;
      }
    }
  }
}

.product-box-container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.product-boxes {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 250px;
  height: 151px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-left: 10px;
}

.active-tab {
  color: #219653 !important;
}

.ant-switch-checked {
  background-color: #219653 !important;
}

.product-text {
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 61px;
  letter-spacing: 0.5px;
  color: #000000;
}

.product-text2 {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.5);
}

.dashboard-two-col-wrap .dasboard-col-one {
  width: 60%;
  // padding-bottom: 140px;
  @media (max-width: 426px) {
    border-right: 0;
    // padding-bottom: 140px;
  }
}
.wayapay-div .dasboard-col-one {
  // min-height: 300px;
  border-right: #e5e5e5 1px solid;

  .notification-div {
    background: rgba(235, 87, 87, 0.1);
    height: 55px;
    width: 85%;
    padding: 10px;
    img {
      height: 30px;
    }
    p {
      font-size: 15px;
    }
  }
}
.miditems-top-tabs {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-around;
  border-bottom: #e5e5e5 1px solid;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.mid-items-tab {
  cursor: pointer;
  color: #858585;
  text-align: center;
}

.mid-items-tab:hover {
  color: #ff6700;
}

.mid-bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 185px;

  margin-right: 30px;
}
.card-content {
  margin: 30px 0;
  position: relative;
  background: #0d0d0d !important;
  border: 1px solid #e0e0e0;
  border-radius: 14px;
  height: 290px !important;
  width: 85%;
}
.search-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 77%;
  height: 45px;
  border: #e5e5e5 1px solid;
  border-radius: 8px;
  @media (max-width: 426px) {
    width: 95%;
  }
  .trans-btn {
    width: 40%;
    height: 100%;
    background: #f8f8f8;
    padding: 8px;
    text-align: center;
    p {
      font-size: 20px;
    }
    @media (max-width: 1150px) {
      p {
        font-size: 14px;
      }
    }
    @media (max-width: 426px) {
      p {
        font-size: 12px;
      }
    }
  }
}
.sort-select {
  border-color: transparent;
  background-color: #fff;
  height: 100%;
  width: 37px;
}
.search-filter > input {
  height: 100%;
  border-color: transparent;
}
.transaction-history {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  // width: 93%;
  // .border-line {
  //   border-bottom: 1px solid #e0e0e0;
  // }
}
.history-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  .h-amount img {
    height: 18px;
  }
}

.history-wrap2 {
  width: 83%;
  // @media (max-width: 426px) {
  //   width: 95% !important;
  // }
}

.transaction-details {
  display: flex;
  align-items: center;
  // justify-content: space-between;
}
.detail-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // @media (max-width: 426px) {
  //   width: 85% !important;
  // }
}

.dashboard-two-col-wrap .dashboard-col-two {
  width: calc(40% - 125px);
  height: 100vh;
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 5px;
}

.items-right-list-item {
  display: flex;
  align-items: center;
  height: 42px;
  margin: 10px 0;
  width: 70%;
  background-color: #fbfbfb;
  border-radius: 4px;
  box-shadow: 5px 3px #f5f5f5;
}
.items-right-list-item img {
  margin-right: 10px;
}
.items-right-list {
  margin: 0 20px;
}
.items-right-bottom {
  margin-top: 40px;
  margin-left: 20px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
}
.top-500 {
  top: 500px;
}
.top-350 {
  top: 350px;
}
.bottom-item-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
  position: absolute;
  width: 348px;
  height: 219px;
  right: 20px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: 1115px) {
    top: 650px;
  }
  @media (max-width: 820px) {
    top: 700px;
  }
  @media (max-width: 768px) {
    position: static;
    margin-bottom: 80px;
    margin-right: 10px;
  }
}
.bottom-item-box h4 {
  text-align: center;
  font-family: san-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 37.73px;
  color: #333333;
}
.bottom-item-box p {
  text-align: center;
  font-family: san-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
}
.top-item-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
  position: absolute;
  width: 348px;
  height: 219px;
  right: 20px;
  // top: 550px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: 1115px) {
    // top: 650px;
  }
  @media (max-width: 820px) {
    // top: 700px;
  }
  @media (max-width: 768px) {
    position: static;
    margin-bottom: 80px;
    margin-right: 10px;
  }
}
.top-item-box h4 {
  text-align: center;
  font-family: san-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 37.73px;
}
.top-item-box p {
  text-align: center;
  font-family: san-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.app-store-btn-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ios-store-2 {
  margin-right: 4px;
}
.google-store-2 {
  margin-left: 4px;
}

.wayapay-wallet {
  margin: 30px 0 15px 0;
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 14px;
  // height: 290px !important;
  width: 100% !important;
  @media (max-width: 991px) {
    width: 100% !important;
  }
  .carousel.carousel-slider .control-arrow {
    top: 50px !important;
    border-radius: 50% !important;
    background: #fff !important;
    height: 50px !important;
    width: 50px !important;
  }
  .wayapay-wallet .carousel.carousel-slider .control-prev {
    right: 0 !important;
  }
  .wayapay-wallet .carousel.carousel-slider .control-next {
    right: -21px !important;
  }
  .carousel.carousel-slider {
    overflow: inherit !important;
  }
  .slider-wrapper {
    border-radius: 16px;
  }
  .each-item {
    position: relative;
    background: #0d0d0d !important;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    height: 160px !important;
  }
  .each-item_2 {
    position: relative;
    background: #fff !important;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    height: 150px !important;
    width: 500px !important;
    margin-right: 50px;
  }
}

// transaction history
#transaction-history {
  .trans-sort-tab {
    justify-content: space-around;
  }
}
.last_one {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.last_two {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}
.round {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #828282;
  margin: 0px 2px;
}
.scrollLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
  width: fit-content;
}
.scrollLeftContainer {
  width: 100%;
  overflow-x: scroll;
  padding: 10px 0;
}
.letterImg {
  width: 50px;
  height: 50px;
  background-color: #ff6634;
  border-radius: 25px;
  border: 1px solid #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Contacts

.contacts-item {
  .inner {
    padding: 10px 30px !important;
    border-bottom: 1px solid #edeaea;
    @media (max-width: 426px) {
      padding: 10px !important;
    }
  }
  // .action-btn {
  //   width: 220px !important;
  // }
}

// misscealleneous stylings
.rounded-6 {
  border-radius: 15px;
}
.h-200 {
  min-height: 280px;
}

.note-alert {
  background: #c4c4c4;
  border-radius: 4px;
  padding: 10px;
}

.transaction {
  width: 100%;
  height: 100%;
}

.TransactionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.textInput {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0px 20px;
  height: 35px;
  border-radius: 5px;
}
.btn_here {
  height: 35px !important;
  background-color: red;
  color: #fff;
  padding: 5px 20px;
  margin-left: 2px;
  cursor: pointer;
}
.btn_here_filter {
  height: 35px !important;
  color: #fff;
  padding: 5px 10px;
  margin-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;
}
.btn_Export {
  height: 37px !important;
  background-color: #fff;
  color: #ff6700;
  padding: 10px 15px;
  margin-left: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;

}
.mx-10 {
  margin: 0px 40px;
}
.mx-5 {
  margin: 0px 5px;
}
.filtButton {
  position: relative;
}
.TransactionPropOverContainer {
  position: absolute;
  width: 300px;
  border: 1px solid #ebebeb;
  padding: 15px;
  z-index: 100;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 10px;
  animation: scaleIt 0.5s ease-in-out;
}
.selectInput {
  margin-bottom: 8px;
}
.flex-filterBtns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.date-styles {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0px 5px;
  height: 40px;
  border-radius: 5px;
}
@keyframes scaleIt {
  from {
    opacity: 0.2;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.background_filter {
  position: absolute;
  background-color: red;
  z-index: 50;
  top: -200px;
  opacity: 0;
}
.newBtn {
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px #e5e5e5;
  border-radius: 2px;
  padding: 10px 15px;
  width: 70px;
}
.non_waya_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: #0000001e 0px 1px 5px 1px;
  padding: 10px;
  width: 22%;
  height: 120px;
  .category {
    font-size: 18px;
  }
  .amount {
    font-size: 25px;
    font-weight: 600;
  }
}
.non_waya_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
}
.transaction_tabs {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: #0000001e 0px 1px 5px 1px;
  margin-bottom: 5px;
  .item {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 10px;
    margin-left: 5px;
  }
  .active {
    color: #ff6700;
  }
  .rounded {
    box-shadow: #0000001e 0px 1px 5px 1px;
    border-radius: 25% !important;
    padding: 10px 20px !important;
  }
}
