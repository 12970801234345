.notfound-body {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* background: #b0127a; */
  background: #ff6634;
  z-index: 10;
}

.page-has-right-panels,
.page-has-right-panels .header {
  padding-right: 70px;
}

.page-has-left-panels {
  padding-left: 70px;
}

#main-panel-container .notfound-section {
  position: relative;
  z-index: 1;
  display: block;
  text-align: center;
  color: #fff;
}

#main-panel-container .page-error {
  height: 100%;
  width: 100%;
  padding-top: 60px;
  text-align: center;
  display: flex;
}

#main-panel-container .page-error .page-inner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}

#main-panel-container .page-error .page-search {
  margin: 20px auto;
  max-width: 100%;
  width: 350px;
}

#main-panel-container .btn {
  color: #fff;
  margin-bottom: 15px;
  position: relative;
}

#main-panel-container a {
  transition: all 0.15s ease;
}

#main-panel-container .btn-verify {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

#main-panel-container .btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
