.dontaion-progress-container {
  .dontaion-progress-box {
    position: relative;
    width: 13em;
    height: 3px;
    background-color: #f2f2f2;
    border-radius: 1px;
  }
  .current-progress {
    background-color: #27AE60;
    width: 7em;
    height: 3px;
    border-radius: 1px;
  }
  .donation-detail-group {
    display: flex;
    align-items: center;
    height: 2em;
    margin-top: 0.4em;
    font-size: 0.85em;
    img {
      margin-right: 1em;
      margin-top: -0.3em;
    }
  }
}