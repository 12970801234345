#single-request-wrapper {
  .footer {
    button {
      width: 60%;
      padding: auto;
    }
    .btn-reject {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
}

.sub-header {
  .sub-text {
    @media (max-width: 426px) {
      font-size: 10px;
    }
  }
}

.request-details {
  justify-content: space-evenly !important;
  .text-right {
    text-align: right;
  }
}

.request-details:hover {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1);
}
.list{
  padding: 8px 15px;
  cursor: pointer;
}