.image-grid-div {
  width: 90%;
  /* background-color: white; */
  display: grid;
  grid-template-areas:
    'grid1 grid2 grid3'
    'grid4 grid2 grid5'
    'grid6 grid7 grid9'
    'grid8 grid10 grid9'
    'grid11 grid12 grid13'
    'grid14 grid15 grid16'
    'grid17 grid18 grid19';
  grid-template-rows: 150px;
  grid-gap: 10px;
  height: 990px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
}

.image-grid-div-1 {
  width: 90%;
  display: grid;
  grid-template-areas: 'grid1';
  grid-template-rows: 150px;
  grid-gap: 10px;
  padding: 20px;
  border-radius: 5px;
}

.image-grid-div-2 {
  width: 90%;
  display: grid;
  grid-template-areas: 'grid1 grid2';
  grid-template-rows: 150px;
  grid-gap: 10px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
}

.image-grid-div-3 {
  width: 100%;
  display: grid;
  grid-template-areas:
    'grid1 grid2'
    'grid1 grid3';
  grid-template-rows: 150px;
  grid-gap: 10px;
  padding: 20px;
  border-radius: 5px;
  height: 300px;
}

.image-grid-div-4 {
  width: 100%;
  display: grid;
  grid-template-areas:
    'grid1 grid2'
    'grid3 grid4';
  grid-template-rows: 150px;
  grid-gap: 10px;
  padding: 20px;
  border-radius: 5px;
  height: 340px;
}

.image-each {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
  position: relative;
}

.image-each-active {
  background: #efae78;
  /* background: #000; */
  opacity: 0.8;
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-markup {
  position: absolute;
  top: 0;
  left: 0;
}

.intreast1 {
  width: 100%;
  height: 100%;
  grid-area: grid1;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
  /* overflow: hidden; */
}

/* .intreast1:hover{
  transform: translateY(-5px);
  transition: all 300ms ease-out;
  overflow: hidden;
} */

.intreast2 {
  width: 100%;
  height: 100%;
  grid-area: grid2;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast3 {
  width: 100%;
  height: 100%;
  grid-area: grid3;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast4 {
  width: 100%;
  height: 100%;
  grid-area: grid4;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast5 {
  width: 100%;
  height: 100%;
  grid-area: grid5;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast6 {
  width: 100%;
  height: 100%;
  grid-area: grid6;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast7 {
  width: 100%;
  height: 100%;
  grid-area: grid7;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast8 {
  width: 100%;
  height: 100%;
  grid-area: grid8;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast9 {
  width: 100%;
  height: 100%;
  grid-area: grid9;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast10 {
  width: 100%;
  height: 100%;
  grid-area: grid10;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast11 {
  width: 100%;
  height: 100%;
  grid-area: grid11;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast12 {
  width: 100%;
  height: 100%;
  grid-area: grid12;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.intreast13 {
  width: 100%;
  height: 100%;
  grid-area: grid13;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: 20px;
}

.image-btn-grp {
  text-align: center;
}

.image-btn-grp button {
  background: #2a9d8f;
  border-radius: 5px;
  color: #fff;
  padding: 5px 15px;
  border: none;
  margin-right: 15px;
}

@media (max-width: 585px) {
  .intreasts-section {
    width: 100vw;
    height: 900px;
  }
}

.follow-cards-678 {
  display: flex;
  /* justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px; */
  width: 100%;
  overflow-x: scroll;
  justify-content: center;
}

.follow-cards-678::-webkit-scrollbar {
  width: 1px;
  opacity: 0;
  visibility: hidden;
}

.follow-cards-678 .followData {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 230px;
  flex-direction: column;
  background-color: white;
  /* width: 150px; */
  min-width: 150px;
  padding: 20px;
  border-radius: 5px;
  margin-right: 10px;
}

.follow-cards-678 .followData img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.follow-cards-678 .followData h1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.0948226px;
  color: #000000;
  margin-top: 12px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.follow-cards-678 .followData h2 {
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  letter-spacing: 0.0948226px;
  color: #c7c7c7;
  margin-top: 12px;
  margin-bottom: 12px;
}

.follow-cards-678 .followData button {
  background: #83c986;
  border-radius: 2px;
  width: 100%;
  height: 35px;
  color: white;
  outline-width: 600;
  border: none;
  padding: 3px 15px;
}
