.option1 {
  position: absolute;
  top: -14px;
  right: 45px;
}

.viewMore {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  color: #064a72;
}

.momentHead {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0d0d0d;
}

.request-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  .request {
    display: flex;
    width: 100%;
    border-top: 1px solid #dee2ea;
    border-bottom: 1px solid #dee2ea;
    padding: 1.5rem;
    .req-img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      margin-right: 12px;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
    .req-det {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 42px);
      .det-det {
        display: flex;
        flex-direction: column;
      }
      .det-button {
        display: flex;
        .btn-acc {
          background: #27ae60;
          border: #27ae60;
          border-radius: 2px;
          padding: 5px 6px;
          text-align: center;
          color: #fbfbfb;
          margin-right: 21px;
          width: 80px;
        }
        .btn-del {
          background: #ff3a44;
          border: #ff3a44;
          border-radius: 2px;
          padding: 5px 6px;
          text-align: center;
          color: #fbfbfb;
          width: 80px;
        }
        .btn-following {
          background: #ff6634;
          border: #ff6634;
          border-radius: 2px;
          padding: 5px 6px;
          text-align: center;
          color: #fbfbfb;
          width: 80px;
          margin-right: 21px;
        }
        .btn-follow {
          background: #fbfbfb;
          border: 0.5px solid #ff6634;
          border-radius: 2px;
          padding: 5px 6px;
          text-align: center;
          color: #ff6634;
          margin-right: 21px;
          width: 80px;
        }
      }
    }
  }
}
