.dispute-header {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 70px;
  display: flex;
  align-items: center;
}

.createDispute {
  padding: 50px;
  width: 100%;
}
.pg-1-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.pg-1-input textarea {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
}
.title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  margin-bottom: 9px;
}
.pg-1-select {
  background: #ffffff;
  border: 1px solid #000000 !important;
  box-sizing: border-box;
  border-radius: 5px !important;
  width: 100%;
  padding: 10px !important;
}

.pg-1-input select,
.pg-1-input input {
  background: #ffffff;
  border: 1px solid #000000 !important;
  box-sizing: border-box;
  border-radius: 5px !important;
  width: 100%;
  padding: 10px !important;
}
.page-1 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 41px;
}

.btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn-div button {
  width: 75%;
  padding-top: 13px;
  padding-bottom: 13px;
}
#dispute-modal .modal-content {
  padding: 50px;
}
.attach-div {
  width: 100%;
  justify-content: flex-start;
}
.attach-div .head {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #151522;
  margin-top: 24px;
  margin-bottom: 13px;
}
.attach-div .attach-img {
  display: flex;
}
.attach-img .img-cont {
  display: flex;
  flex-direction: column;
  margin-right: 13px;
  margin-bottom: 16px;
}

.attach-img .img-cont img {
  margin: auto;
}

.pg-check {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  width: 100%;
  display: flex;
  align-items: center;
}

.pg-check .check input {
  background: #ffffff;
  border: 1px solid #828282;
}
