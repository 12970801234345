.row {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
}
.col {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
}
