.finish-card {
  width: 60%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 6px;
  background: #ff6634;
  color: #fff;
  cursor: pointer;
  margin-bottom: 2em;
}

.finish-card:hover {
  background: #fff;
  border: 1px solid #ff6634;
  color: #ff6634;
}