#feedback-modal {
  .modal-header {
    background: #f1f7fa;
    button {
      border: none;
    }
  }
  .modal-body {
    min-height: 40vh;
    .textarea {
      height: 36vh;
      width: 100%;
      border: 1px solid #cdcdcd;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 1em 2em;
    }
    .textarea_2 {
      height: 30px;
      width: 100%;
      border: 1px solid #cdcdcd;
      box-sizing: border-box;
      border-radius: 4px;
      padding-left: 1rem;
    }
  }
  .feedback-button {
    background: #ff6634;
    border-radius: 4px;
    color: #fff;
    width: 6.25em;
    height: 2.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

#blockedUser-modal {
  .modal-header {
    display: flex;
    justify-content: flex-end;
    background: #f1f7fa;
    .modal-title {
      width: 60%;
    }
    .custome-header {
      width: 100%;
      display: flex;
    }
    button {
      border: none;
    }
  }
  .modal-body {
    padding: 0px;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .blocked-account-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 3.5em;
      padding: 0 1.6em 0 1em;
      .account-group {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .resize-icon {
        font-size: 0.7em;
      }
      img {
        width: 2.6em;
        height: 2.6em;
        border-radius: 50%;
      }
    }
  }
}
#Terms-modal {
  .modal-header {
    display: flex;
    justify-content: flex-end;
    background: #f1f7fa;
    .modal-title {
      width: 60%;
    }
    .custome-header {
      width: 100%;
      display: flex;
    }
    button {
      border: none;
    }
  }
  .modal-body {
    padding: 0px;
    min-height: 70vh;
  }
}

#accountSettings {
  .head {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
  }
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.25);
  .profile-detail {
    display: flex;
    justify-content: space-between;
    .pd-1 {
      .avatar-case {
        width: 80px;
        margin: auto;
        height: 80px;
        border-radius: 50%;
        background-color: #fdaf8e;
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        color: #4f4f4f;
        .avatar-text {
          top: 20px;
          margin: auto;
          text-align: center;
          width: 80px;
          height: 80px;
          position: relative;
        }
      }
      .pd-1-t {
        margin-top: 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #4f4f4f;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .pd-1-b {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #333333;
      }
    }
    .pd-2 {
      display: flex;
      flex-direction: column;
      .pd-2-t {
        display: flex;
        text-align: end;
        justify-content: flex-end;
        span {
          color: #ff6634;
        }
        img {
          margin-right: 9px;
          margin-bottom: 35px;
        }
      }
      .pd-2-b {
        padding: 25px;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        margin-top: 10px;
        .pd-2-b-1 {
          display: flex;
          flex-direction: column;
          margin-right: 25px;
          .pd2b1-1 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #333333;
            width: 100%;
            height: 19px;
            margin-bottom: 6px;
          }
          button {
            padding: 10px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            background: #ff6634;
            border-radius: 4px;
            border: 0;
            height: 30px;
            color: #ffffff;
          }
        }
        .pd-2-b-2 {
          flex-direction: column;
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
  .account-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .options {
      display: flex;
      padding: 13px;
      width: 46%;
      background: #ffffff;
      box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-top: 15px;
      margin-right: 17px;
      justify-content: space-between;
    }
  }
  .log-out {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ff6634;
  }
  .tier {
    .head {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #252733;
      text-align: center;
    }
    .info {
      display: flex;
      justify-content: space-between;
      .info-1 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #1c1c1c;
      }
      .info-2 {
        display: flex;
        flex-direction: column;
        .info-2-t {
          font-family: 'Lucida Bright';
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 21px;
          display: flex;
          align-items: center;
          letter-spacing: -0.02em;
          color: #333333;
        }
        .info-2-b {
          background: #27ae60;
          border: 0.5px solid #e5e5e5;
          box-sizing: border-box;
          box-shadow: 0px 0px 0px #e5e5e5;
          border-radius: 2px;
          font-family: 'Lucida Bright';
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 21px;
          display: flex;
          align-items: center;
          letter-spacing: -0.02em;
          color: #ffffff;
        }

        .info-2-b-true {
          background: #27ae60;
          border: 0.5px solid #e5e5e5;
          box-sizing: border-box;
          box-shadow: 0px 0px 0px #e5e5e5;
          border-radius: 2px;
          font-family: 'Lucida Bright';
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 21px;
          display: flex;
          align-items: center;
          letter-spacing: -0.02em;
          color: #ffffff;
        }
        .info-2-b-false {
          background: red;
          border: 0.5px solid #e5e5e5;
          box-sizing: border-box;
          box-shadow: 0px 0px 0px #e5e5e5;
          border-radius: 2px;
          font-family: 'Lucida Bright';
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 21px;
          display: flex;
          align-items: center;
          letter-spacing: -0.02em;
          color: #ffffff;
        }
      }
    }
    table {
      .upload1 {
        width: 85px;
        margin: auto;
        bottom: -32px;
        opacity: 0;
        position: relative;
      }
      .upload2 {
        width: 85px;
        margin: auto;
        bottom: 31px;
        opacity: 0;
        position: relative;
      }
      .upload3 {
        position: absolute;
        width: 104px;
        top: 332px;
        opacity: 0;
      }
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      thead,
      tr {
        border-bottom: 1px solid #e0e0e0;
      }
      tr {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #1c1c1c;
      }

      th,
      td {
        border-right: 1px solid #e0e0e0;
        margin: auto;
        text-align: center;
      }
      tbody {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;

        color: #1c1c1c;
      }

      .action {
        width: 105px;
        background: #f8f8f8;
        border: 1px solid #e0e0e0;
        box-shadow: 0px 0px 0px #e5e5e5;
        border-radius: 2px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #1c1c1c;
        padding: 5px 10px;
        margin: auto;
      }
      .status {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #27ae60;
        width: fit-content;
        margin: auto;
        text-align: center;
      }
      .status-true {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #27ae60;
        width: fit-content;
        margin: auto;
        text-align: center;
      }
      .status-pending {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #d4af37;
        width: fit-content;
        margin: auto;
        text-align: center;
      }
      .status-false {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: red;
        width: fit-content;
        margin: auto;
        text-align: center;
      }
    }
    .limit {
      .limit-info {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #1c1c1c;
      }
    }
    .tier-foot {
      align-items: center;
      margin-top: 100px;
      display: flex;
      justify-content: space-between;
      .tier-foot-1 {
        font-family: 'Lucida Bright';
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #1c1c1c;
      }
      .tier-foot-2 {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        box-shadow: 0px 0px 0px #e5e5e5;
        border-radius: 2px;
        display: flex;
        padding: 0px 3px;
        margin: auto;
        font-family: 'Lucida Bright';
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 21px;
        align-items: center;
        letter-spacing: -0.02em;
        cursor: pointer;
        .btn {
          background: #ffffff;
          border: 0 !important;
          box-sizing: border-box;
          box-shadow: 0px 0px 0px #e5e5e5;
          border-radius: 2px;
          padding: 0 5px;
          font-family: 'Lucida Bright';
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 21px;
          display: flex;
          align-items: center;
          letter-spacing: -0.02em;
          color: #858585;
        }

        color: #858585;
        .page {
          background: #f8f8f8;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          box-shadow: 0px 0px 0px #e5e5e5;
          border-radius: 2px;
          font-family: 'Lucida Bright';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 21px;
          display: flex;
          align-items: center;
          letter-spacing: -0.02em;
          color: #858585;
          padding: 2px 10px;
        }
      }
    }
  }
}

.account-statement {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  .as-head {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    margin-bottom: 70px;
    margin-top: 15px;
    text-align: center;
    .temp {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      text-align: center;

      color: #333333;
    }
  }
  .as-body {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: #333333;
    input,
    select {
      width: 100%;
      padding: 11px 17px;
      border: 1px solid #e0e0e0;
      margin-top: 12px;
      margin-bottom: 14px;
      ::placeholder {
        font-family: 'Lucida Bright';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
      }
    }
  }
  .as-button {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-top: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    button {
      width: 100%;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #ffffff;
      padding-top: 5px;
      padding-bottom: 5px;
      background: #ff6634;
      border-radius: 4px;
      border: #ff6634;
    }
  }
}

.team-btn {
  background: #ff6700 !important;
  border: 0.5px solid #e5e5e5 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 0px 0px #e5e5e5 !important;
  border-radius: 2px !important;
  color: #ffffff !important;
  i {
    color: #ffffff;
  }
}

.manage-btn {
  background: #f2f2f2 !important;
  border: 0.5px solid #e5e5e5 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 0px 0px #e5e5e5 !important;
  border-radius: 2px !important;
  color: #333333 !important;
  i {
    color: #333333;
  }
}
.manage-roles {
  display: flex;
  .fa-circle {
    color: #000000;
    font-size: 10px;
  }
  .role-list {
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 10px;
    flex-basis: 48%;
  }
  .border-color {
    border: 1px solid #219653;
  }
  .border-color1 {
    border: 1px solid #c67777;
  }
  .customer-roles {
    display: flex;
    justify-content: space-between;
  }
  .head-color {
    background: rgba(139, 184, 159, 1);
    color: rgba(82, 147, 111, 1);
  }
  .head-color1 {
    background: #e3d3d3;
    color: rgba(198, 119, 119, 1);
  }
  .dark-color {
    background: #f7f7f7;
    color: #000000;
  }
  .light-color {
    background: #ffffff;
    color: rgba(82, 147, 111, 1);
    color: #000000;
  }
  .list-item {
    border-radius: 4px;
    padding: 17px;
    height: 50px;
    p {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .desc {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #828282;
  }
}
.ant-card-head {
  border-bottom: 0px !important;
}
.ant-table-thead > tr > th {
  background-color: #ffffff !important;
  color: #1c1c1c !important;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffefe9;
}

.ant-menu-item-selected {
  color: #ff6700 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffefe9 !important;
}
.ant-menu-light .ant-menu-item:hover .ant-menu-light .ant-menu-item-active {
  color: #ff6700 !important;
}
.ant-menu-vertical > .ant-menu-item {
  color: #828282;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
}
.ant-menu-vertical:hover {
  color: #ff6700;
}

.setting-menu::-webkit-scrollbar {
  width: 5px;
}
.setting-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.setting-menu::-webkit-scrollbar-thumb {
  background: #888;
}
.setting-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.btn.btn-outline-success {
  background-color: rgb(208, 255, 208);
}
.btn.btn-outline-info {
  background-color: rgb(208, 240, 255);
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.fit_content {
  width: fit-content;
}
.trans {
  transition: all 0.4s ease-in-out;
}
.rotate {
  transform: rotate(90deg);
}
.slideDown{
  animation: done 0.4s ease-in-out forwards;
}
.color {
  color: #ff6634;
}

@keyframes done {
  from{
    transform: translateY(-20px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
}
.backBtn{
  padding: 10px 15px;
  cursor: pointer;
}
