.text {
  font-family: 'DM Sans';
  font-style: normal;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.3px;

  font-weight: 400;
}
.text2 {
  font-family: 'DM Sans';
  font-style: normal;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.3px;
  font-weight: 600;
}
.text3 {
  font-family: 'DM Sans';
  font-style: normal;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.3px;

  font-weight: 200;
}
