.button_First {
  background-color: var(--red);
  padding: 10px 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_Second {
  background-color: var(--red);
  padding: 5px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forward {
  padding-top: 5px;
  margin-left: 5px;
}
.button_plain {
  background-color: #ffffff;
  padding: 7px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 16px;
  width: 100px;
}
.button_withLeft {
  /* background-color: var(--red); */
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
}

.the_icon {
  margin-right: 5px;
}
