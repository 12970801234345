.year-input {
  margin-left: 35px;
  @media (max-width: 426px) {
    margin-left: 0;
    width: 70% !important;
  }
}

.month-input {
  @media (max-width: 426px) {
    width: 70% !important;
  }
}

.custom-my {
  margin-bottom: 25px;
  .inputbox-with-one-input {
    margin-bottom: 0 !important;
  }
}

.addCard-body-img {
  text-align: center;
  position: relative;
  .addCard-title {
    position: absolute;
    margin-left: 15px;
    margin-top: 5px;
    color: #fff;
    font-size: 20px;
    letter-spacing: 4px;
  }
  img {
    width: 70%;
    @media (max-width: 426px) {
      width: 100% !important;
    }
  }
}

.addCard-info {
  position: absolute;
  bottom: 31px;
  width: 100%;
  padding: 0px 90px;
  color: #fff;
  @media (max-width: 426px) {
    bottom: 6px;
    padding: 5px !important;
  }
  .d-flex {
    justify-content: space-between;
    @media (max-width: 426px) {
      width: 255px !important;
    }
  }
}
